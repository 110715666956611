import api from "../api/dropdowns";

const options = {
  endpoint: "api/admin",
  data: {},
  param:{}
};

export default {
  getBPMUsers(name,formType) {
    return api.getBPMUsers(options, name,formType);
  },

  getUIDatas(name) {
    return api.getUIDatas(options, name);
  },

  RemoveFileFromDirectory(param){    
    return api.RemoveFileFromDirectory(options,param);
  },

  RemoveFileFromProfileDirectory(param){    
    return api.RemoveFileFromProfileDirectory(options,param);
  }, 
 

  removeDiretory(guid){    
     return api.RemoveTempDirectory(options,guid);
  },

  UpdateICRequestGUID(requestId,guid)
  {    
    return api.UpdateICRequestGUID(options,requestId,guid);
  },

  RemoveClientTempDirectory(param){    
    return api.RemoveClientTempDirectory(options,param);
 },
  
  getEntityRecords(name) {
    return api.getEntityRecords(options, name);
  },
    
  getOriginalEntityRecords(name) {
    return api.getOriginalEntityRecords(options, name);
  },
  
  getAgencyDropdownValues(name) {        
    return api.getAgencyRecords(options,name);
  },
  getAllAgencyDropdownValues(name) {        
    return api.getAllAgencyRecords(options,name);
  },

  getCheckHfmCodeExist(name){    
    return api.GetCheckHFMCodeExists(options,name);
  },

  getCurrencyDropdownValues() {
    return api.getCurrencyList(options, "");
  },

  getPaymentDropdownValues() {
    return api.getPaymentTerms(options, "");
  },

  getOfficeDropdownValues() {
    return api.getOfficeLocations(options, "");
  },

  getHFMMasterClientCodeDropdownValues(){
    return  api.getHFMMasterClientCode(options,"");
  },

  getmediaTypeDropdownValues() {
    return api.getMediaTypeList(options, "");
  },

  getCountryDropdownValues() {
    return api.getCountryList(options, "");
  },

  getAgencyNetworkRegions(name){
    return api.getAgencyNetworkRegion(options,name);     
  },
  
  getWeatherForeCast(){
    return api.getWeatherForeCast();
  }
};
